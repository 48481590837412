import { Auth, TradeDetails, TradeDetailsPage, TradeDetailsTopic } from 'components'
import { db } from '../../firebase'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Backdrop, CircularProgress } from '@mui/material'
import { Collection } from 'types'

export const Edit = () => {
  const { id } = useParams()
  const [trade, setTrade] = useState<TradeDetails>()

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        // TODO: handle trade not found
        const tradeData = (await getDoc(doc(db, Collection.Trades, id))).data()

        const topics: TradeDetailsTopic[] = []
        ;(
          await getDocs<TradeDetailsTopic>(
            query(collection(db, Collection.Topics), where('tradeId', '==', id))
          )
        ).forEach((doc) => topics.push({ ...doc.data(), id: doc.id }))

        setTrade({
          ...tradeData,
          id,
          topics,
        })
      }

      fetchData()
    }
  }, [id])

  return (
    <Auth>
      <>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={trade == null}
        >
          <CircularProgress />
        </Backdrop>
        <TradeDetailsPage trade={trade} />
      </>
    </Auth>
  )
}
