import { createBrowserRouter } from 'react-router-dom'
import {
  Trades,
  Advisors,
  Login,
  Content,
  Conversations,
  TradesAdd,
  TradesEdit,
  Invitations,
  AdvisorsAdd,
  AdvisorsEdit,
  ContentEdit,
  ContentAdd,
  DeleteAccount,
} from '../pages'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/trades',
    element: <Trades />,
  },
  {
    path: '/trades/:id',
    element: <TradesEdit />,
  },
  {
    path: '/trades/add',
    element: <TradesAdd />,
  },
  {
    path: '/advisors',
    element: <Advisors />,
  },
  {
    path: '/advisors/:id',
    element: <AdvisorsEdit />,
  },
  {
    path: '/advisors/add',
    element: <AdvisorsAdd />,
  },
  {
    path: '/content',
    element: <Content />,
  },
  {
    path: '/content/:id',
    element: <ContentEdit />,
  },
  {
    path: '/content/add',
    element: <ContentAdd />,
  },
  {
    path: '/conversations',
    element: <Conversations />,
  },
  {
    path: '/invitations/:inviteId',
    element: <Invitations />,
  },
  {
    path: '/delete-account',
    element: <DeleteAccount />,
  }
])
