import { Auth, ContentDetails, ContentDetailsPage } from 'components'
import { db } from '../../firebase'
import { doc, getDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Backdrop, CircularProgress } from '@mui/material'
import { Collection } from 'types'

export const Edit = () => {
  const { id } = useParams()
  const [content, setContent] = useState<ContentDetails>()

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        // TODO: handle errors
        const contentData = (
          await getDoc(doc(db, Collection.Content, id))
        ).data()
        setContent({
          ...contentData,
          id,
        })
      }

      fetchData()
    }
  }, [id])

  return (
    <Auth>
      <>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={content == null}
        >
          <CircularProgress />
        </Backdrop>
        <ContentDetailsPage content={content} />
      </>
    </Auth>
  )
}
