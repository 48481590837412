import { useEffect, useState, useRef } from 'react'
import { db } from '../firebase'
import {
  setDoc,
  doc,
  writeBatch,
  collection,
  deleteDoc,
} from 'firebase/firestore'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Stack,
  Modal,
  Typography,
} from '@mui/material'
import {
  Button,
  CloseIcon,
  ConfirmationModal,
  DetailsPageContainer,
  DetailsPageTitle,
  Footer,
  ModalContent,
  PaddedStack,
  Row,
  TableHead,
  TableRow,
  TableWrapper,
  TitleBar,
  Wrapper,
} from 'components'
import { Add as AddIcon, WarningAmber } from '@mui/icons-material'
import styled from 'styled-components'
import { Collection } from 'types'

const tradeError = 'Please enter trade name'
const topicError = 'Please enter topic name'

const DeleteButtonContainer = styled(Row)`
  margin-top: 80px;
`

interface TopicDocumentData {
  id?: string
  title?: string
  tradeId?: string
}

export interface TradeDetailsTopic {
  id?: string
  title?: string
  tradeId?: string
}

export interface TradeDetails {
  advisorIds?: string[]
  id: string
  title?: string
  topics?: TradeDetailsTopic[]
}

interface TradeDetailsPageProps {
  trade?: TradeDetails
}

export const TradeDetailsPage = (props: TradeDetailsPageProps = {}) => {
  const navigate = useNavigate()
  const [fieldError, setFieldError] = useState<string | null>(null)
  const [newTradeTitle, setNewTradeTitle] = useState<string>()
  const [unsavedTopics, setUnsavedTopics] = useState<TradeDetailsTopic[]>([])
  const [newTopicTitle, setNewTopicTitle] = useState<string>('')
  const [selectedTopicIndex, setSelectedTopicIndex] = useState<number>()
  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false)
  const [isTradeModalOpen, setIsTradeModalOpen] = useState(false)
  const topicFieldRef = useRef<any>(null)

  useEffect(() => {
    setNewTradeTitle(props.trade?.title)
    setFieldError(null)

    const sortedTopics = getSortedTopics(props.trade?.topics)
    setUnsavedTopics(sortedTopics)
  }, [props.trade?.title, props.trade?.topics])

  const pageTitle = props.trade ? 'Edit Trade' : 'New Trade'
  const selectedTopic =
    !!selectedTopicIndex || selectedTopicIndex === 0
      ? unsavedTopics[selectedTopicIndex]
      : undefined
  const topicModalTitle = selectedTopic?.title ? 'Edit Topic' : 'Add Topic'
  const modalConfirmButtonText = selectedTopic?.title ? 'OK' : 'Add'

  const closeTopicModal = () => {
    setSelectedTopicIndex(undefined)
    setNewTopicTitle('')
    setIsTopicModalOpen(false)
  }
  const openTopicModal = (i?: number) => {
    setSelectedTopicIndex(i)
    setIsTopicModalOpen(true)
    setTimeout(() => {
      topicFieldRef.current?.focus()
    }, 100)
  }

  const closeTradeModal = () => {
    setIsTradeModalOpen(false)
  }
  const openTradeModal = () => {
    setIsTradeModalOpen(true)
  }
  const deleteTrade = async () => {
    if (props.trade?.id) {
      const batch = writeBatch(db)
      props.trade?.topics
        ?.filter((topic) => topic.id)
        .forEach((topic) => {
          batch.delete(doc(db, Collection.Topics, topic.id!))
        })

      await Promise.all([
        deleteDoc(doc(db, Collection.Trades, props.trade.id)),
        batch.commit(),
      ])

      navigate('/trades')
    }
  }
  const TradeDeletionModal = (
    <ConfirmationModal
      confirmButtonLabel={'Delete'}
      isOpen={isTradeModalOpen}
      message={
        <>
          Are you sure you want to delete trade “
          <strong>{newTradeTitle}</strong>”?
        </>
      }
      modalTitle={'Delete trade'}
      onClose={closeTradeModal}
      onConfirm={deleteTrade}
    />
  )

  const saveChanges = async () => {
    if (!newTradeTitle) {
      setFieldError(tradeError)
      return
    }

    const docId = props.trade?.id ?? uuid()
    const batch = writeBatch(db)

    unsavedTopics.forEach((topic) => {
      const docRef = topic.id
        ? doc(db, Collection.Topics, topic.id)
        : doc(collection(db, Collection.Topics))
      batch.set<TopicDocumentData>(docRef, {
        ...topic,
        tradeId: docId,
      })
    })

    const deletedTopics = props.trade?.topics?.filter(
      (t) => !unsavedTopics.some((unsavedTopic) => unsavedTopic.id === t.id)
    )
    deletedTopics?.forEach((topic) => {
      batch.delete(doc(db, Collection.Topics, topic.id!))
    })

    await Promise.all([
      setDoc(doc(db, Collection.Trades, docId), {
        ...props.trade,
        advisorIds: props.trade?.advisorIds ?? [],
        title: newTradeTitle,
      }),
      batch.commit(),
    ])

    navigate('/trades')
  }

  return (
    <Wrapper>
      <TitleBar>{pageTitle}</TitleBar>
      <DetailsPageContainer>
        <Stack spacing={1}>
          <div> Trade name</div>
          <TextField
            error={fieldError === tradeError}
            helperText={fieldError === tradeError ? fieldError : null}
            placeholder="Enter trade name"
            value={newTradeTitle}
            onChange={(e) => {
              setNewTradeTitle(e.target.value)
              setFieldError(null)
            }}
          />
        </Stack>

        <Row>
          <DetailsPageTitle>Topics</DetailsPageTitle>
          <Button variant="contained" onClick={() => openTopicModal()}>
            <AddIcon />
            Add Topic
          </Button>
        </Row>

        <TableContainer component={TableWrapper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {unsavedTopics.map((topic, i) => (
                <TableRow
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={(e: any) =>
                    e.target.nodeName === 'TH' || e.target.nodeName === 'TD'
                      ? openTopicModal(i)
                      : null
                  }
                >
                  <TableCell component="th" scope="row">
                    {topic.title}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ padding: '0px', paddingRight: '16px' }}
                  >
                    <Button
                      onClick={() =>
                        setUnsavedTopics(
                          (() => {
                            const arr = [...unsavedTopics]
                            arr.splice(i, 1)
                            return arr
                          })()
                        )
                      }
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {props.trade?.id && (
          <DeleteButtonContainer>
            <Button variant="outlined" onClick={() => openTradeModal()}>
              <WarningAmber />
              Delete trade
            </Button>
          </DeleteButtonContainer>
        )}
      </DetailsPageContainer>

      <Modal
        open={isTopicModalOpen}
        onClose={closeTopicModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <Row justify="flex-end">
            <CloseIcon onClick={closeTopicModal} />
          </Row>
          <PaddedStack spacing={3}>
            <Row justify="center">
              <Typography fontSize={28} fontWeight="bold" textAlign="center">
                {topicModalTitle}
              </Typography>
            </Row>
            <Stack spacing={1}>
              <div>Topic name</div>
              <TextField
                error={fieldError === topicError}
                helperText={fieldError === topicError ? fieldError : null}
                ref={(el: any) => {
                  if (el) {
                    topicFieldRef.current = el.children[0].children[0]
                  }
                }}
                autoFocus
                placeholder="Enter topic name"
                defaultValue={selectedTopic?.title}
                onChange={(e) => {
                  setNewTopicTitle(e.target.value)
                  setFieldError(null)
                }}
              />
            </Stack>
            <Row>
              <Button variant="outlined" width={160} onClick={closeTopicModal}>
                Cancel
              </Button>
              <Button
                variant="contained"
                width={160}
                onClick={() => {
                  if (newTopicTitle) {
                    if (!!selectedTopicIndex || selectedTopicIndex === 0) {
                      //edit
                      const arr = [...unsavedTopics]
                      arr[selectedTopicIndex] = {
                        ...(selectedTopic ?? {}),
                        title: newTopicTitle,
                      }
                      setUnsavedTopics(arr)
                    } else {
                      //add
                      setUnsavedTopics(
                        getSortedTopics([
                          { title: newTopicTitle },
                          ...unsavedTopics,
                        ])
                      )
                    }
                    closeTopicModal()
                  } else {
                    setFieldError(topicError)
                  }
                }}
              >
                {modalConfirmButtonText}
              </Button>
            </Row>
          </PaddedStack>
        </ModalContent>
      </Modal>

      <Footer>
        <Button
          variant="outlined"
          width={160}
          onClick={() => navigate('/trades')}
        >
          Cancel
        </Button>
        <Button variant="contained" width={160} onClick={() => saveChanges()}>
          Save
        </Button>
      </Footer>

      {TradeDeletionModal}
    </Wrapper>
  )

  function getSortedTopics(array?: TradeDetailsTopic[]) {
    return [...(array ?? [])].sort((a, b) =>
      (a.title ?? '').localeCompare(b.title ?? '')
    )
  }
}
