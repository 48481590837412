import { CircularProgress } from '@mui/material'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components';
import { Row } from 'components'
import { useUser } from '../context/user'
import { auth } from '../firebase'

const Wrapper = styled.div`
  padding-top: 65px;
  height: 100vh;
`

const Container = styled.div`
  padding: 16px 40px;
`

interface AuthProps {
  children: JSX.Element
}

export const Auth = ({children} : AuthProps): JSX.Element => {
  const [authUser, isLoading] = useAuthState(auth)

  const { user } = useUser()

  if (isLoading) {
    return (
      <Wrapper>
        <Container>
          <Row justify="center">
            <CircularProgress />
          </Row>
        </Container>
      </Wrapper>
    )
  }

  if (!authUser) {
    return (
      <Navigate to="/"/>
    )
  }

  if (!user) {
    return (
      <Wrapper>
        <Container>
          <Row justify="center">
            <CircularProgress />
          </Row>
        </Container>
      </Wrapper>
    )
  }

  if (!user.roles?.includes('root')) {
    return (
      <Navigate to="/"/>
    )
  }

  return children
}
