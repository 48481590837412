import { ReactNode } from 'react'
import { Stack, Modal, Typography } from '@mui/material'
import {
  Button,
  CloseIcon,
  LoadingButton,
  ModalContent,
  PaddedStack,
  Row,
} from 'components'
import styled from 'styled-components'

const CenteredText = styled.p`
  text-align: center;
`

interface ConfirmationModalProps {
  confirmButtonLabel: ReactNode
  isConfirming?: boolean
  isOpen: boolean
  message: ReactNode
  modalTitle: ReactNode
  onClose: () => void
  onConfirm: () => Promise<void>
}

export const ConfirmationModal = ({
  isConfirming,
  ...props
}: ConfirmationModalProps) => {
  const onClose = () => (!isConfirming ? props.onClose() : null)
  return (
    <Modal
      open={props.isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContent>
        <Row justify="flex-end">
          <CloseIcon onClick={onClose} />
        </Row>
        <PaddedStack spacing={3}>
          <Row justify="center">
            <Typography fontSize={28} fontWeight="bold" textAlign="center">
              {props.modalTitle}
            </Typography>
          </Row>
          <Stack spacing={1}>
            <CenteredText>{props.message}</CenteredText>
          </Stack>
          <Row>
            <Button
              variant="outlined"
              width={160}
              onClick={props.onClose}
              disabled={isConfirming}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isConfirming ?? false}
              variant="contained"
              width={160}
              onClick={() => props.onConfirm()}
            >
              {props.confirmButtonLabel}
            </LoadingButton>
          </Row>
        </PaddedStack>
      </ModalContent>
    </Modal>
  )
}
