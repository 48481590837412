import { useEffect, useState } from 'react'
import { db } from '../../firebase'
import { collection, getDocs, query, where } from 'firebase/firestore'
import {
  AdvisorDetails,
  Auth,
  Button,
  CloseIcon,
  Link,
  ListPage,
  Row,
  SelectPlaceholder,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
  TradeDetails,
  UserStatusChip,
} from 'components'
import {
  CircularProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Stack,
} from '@mui/material'
import { Add, Search } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import styled from 'styled-components'
import { Collection } from 'types'

const ListPageTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
`

const TitleWrapper = styled(Stack)`
  margin-top: 16px;
  margin-bottom: 20px;
`

export const Advisors = () => {
  const navigate = useNavigate()
  const [advisors, setAdvisors] = useState<AdvisorDetails[]>()
  const [filteredAdvisors, setFilteredAdvisors] = useState<AdvisorDetails[]>()
  const [searchTerm, setSearchTerm] = useState('')
  const [trades, setTrades] = useState<TradeDetails[]>([])
  const [selectedTradeId, setSelectedTradeId] = useState<string>()

  useEffect(() => {
    const fetchData = async () => {
      const tradeData: TradeDetails[] = []
      await getDocs(collection(db, Collection.Trades)).then((data) =>
        data.forEach((doc) => {
          tradeData.push({ ...doc.data(), id: doc.id })
        })
      )
      setTrades(tradeData)

      const AdvisorTradeIdsMap: Record<string, string[]> = {}

      tradeData.forEach((trade) => {
        ;(trade.advisorIds || []).forEach((advisorId) => {
          if (!AdvisorTradeIdsMap[advisorId]) {
            AdvisorTradeIdsMap[advisorId] = []
          }
          AdvisorTradeIdsMap[advisorId] = [
            ...AdvisorTradeIdsMap[advisorId],
            trade.id,
          ]
        })
      })

      const advisorData: AdvisorDetails[] = []
      await getDocs(
        query(
          collection(db, Collection.Users),
          where('roles', 'array-contains', 'advisor'),
          where('status', '!=', 'Deleted')
        )
      ).then((data) =>
        data.forEach((doc) =>
          advisorData.push({
            ...doc.data(),
            id: doc.id,
            tradeIds: AdvisorTradeIdsMap[doc.id] || [],
          })
        )
      )
      setAdvisors(
        [...advisorData].sort(
          (a, b) =>
            (a.firstName ?? '').localeCompare(b.firstName ?? '') ||
            (a.lastName ?? '').localeCompare(b.lastName ?? '')
        )
      )
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (advisors) {
      setFilteredAdvisors(
        (advisors ?? [])
          .filter(
            (advisor) =>
              !selectedTradeId ||
              advisor.tradeIds?.some((id) => id === selectedTradeId)
          )
          .filter((advisor) => {
            const loweredSearchTerm = searchTerm.toLowerCase()
            return (
              (advisor.firstName ?? '')
                .toLowerCase()
                .includes(loweredSearchTerm) ||
              (advisor.lastName ?? '')
                .toLowerCase()
                .includes(loweredSearchTerm) ||
              advisor.states
                ?.join(', ')
                .toLowerCase()
                .includes(loweredSearchTerm) ||
              advisor.tradeIds
                ?.map((id) => `"${trades.find((t) => t.id === id)?.title}"`)
                .join(', ')
                .toLowerCase()
                .includes(loweredSearchTerm) ||
              (advisor.status ?? '').toLowerCase().includes(loweredSearchTerm)
            )
          })
      )
    }
  }, [advisors, searchTerm, selectedTradeId, trades])

  const tradeFilterChange = (props: SelectChangeEvent) => {
    setSelectedTradeId(props.target.value)
  }

  return (
    <Auth>
      <ListPage>
        <TitleWrapper spacing={2}>
          <ListPageTitle>Advisors</ListPageTitle>

          <Row>
            <Select
              displayEmpty
              defaultValue=""
              onChange={tradeFilterChange}
              size="small"
            >
              <MenuItem value="">
                <SelectPlaceholder>All trades</SelectPlaceholder>
              </MenuItem>
              {trades.map((t, i) => (
                <MenuItem key={i} value={t.id}>
                  {t.title}
                </MenuItem>
              ))}
            </Select>

            <Row spacing={3}>
              <TextField
                size="small"
                InputProps={{
                  startAdornment: <Search color="disabled" />,
                  endAdornment: (
                    <CloseIcon
                      color="disabled"
                      onClick={() => setSearchTerm('')}
                    />
                  ),
                }}
                placeholder="search..."
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <Link to={'/advisors/add'}>
                <Button variant="contained">
                  <Add />
                  Add Advisor
                </Button>
              </Link>
            </Row>
          </Row>
        </TitleWrapper>

        <TableContainer component={TableWrapper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: 180 }}>First name</TableCell>
                <TableCell sx={{ minWidth: 180 }}>Last name</TableCell>
                <TableCell sx={{ minWidth: 180 }}>State</TableCell>
                <TableCell>Trade</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAdvisors ? (
                filteredAdvisors.map((advisor, i) => (
                  <TableRow
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() => navigate(`/advisors/${advisor.id}`)}
                  >
                    <TableCell>{advisor.firstName}</TableCell>
                    <TableCell>{advisor.lastName}</TableCell>
                    <TableCell>{advisor.states?.join(', ')}</TableCell>
                    <TableCell>
                      {advisor.tradeIds
                        ?.map(
                          (id) => `"${trades.find((t) => t.id === id)?.title}"`
                        )
                        .join(', ')}
                    </TableCell>
                    <TableCell align="center">
                      <UserStatusChip
                        hasCompletedProfile={advisor.hasCompletedProfile}
                        hasLinkedStripeAccount={advisor.hasLinkedStripeAccount}
                        userStatus={advisor.status}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell colSpan={5} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ListPage>
    </Auth>
  )
}
