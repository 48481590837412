import {
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from '@mui/material'
import styled from 'styled-components'

export const TableHead = styled(MuiTableHead)`
  background-color: rgb(250, 250, 250);
  border-bottom: 1.1px solid black;
  font-weight: bold;
  & tr {
    & th {
      background-color: rgb(250, 250, 250);
      border-bottom: 1.1px solid black;
      font-weight: bold;
    }
  }
`

export const TableRow = styled(MuiTableRow)`
  &:hover {
    background-color: #f6f8fb;
  }
`

export const TableWrapper = styled.div`
  border: 1px solid rgb(250, 250, 250);
`
