import { useState } from 'react'
import {
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  AppBar,
  Toolbar,
} from '@mui/material'
import { Logout } from '@mui/icons-material'
import styled from 'styled-components'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { auth } from '../firebase'
import { capitalise } from 'utils'
import { useUser } from 'context/user'

const HomeButton = styled.div`
  background-color: #c5c5c5;
  height: 40px;
  width: 120px;
  margin-right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const LinkWrapper = styled.div`
  height: 40px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledLink = styled(Link)`
  background-color: transparent;
  text-align: center;
  margin-left: 20px;
  text-decoration: none;
  color: #101010;
  ${({ selected }: { selected: boolean }) =>
    selected ? 'font-weight: bold; color: #101010;' : 'color: #555555'}
`

const UserIcon = styled.div`
  background-color: #c5c5c5;
  width: 40px;
  height: 40px;
  margin-left: auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Nav = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { user } = useUser()

  const Tab = ({ name }: { name: string }) => (
    <LinkWrapper>
      <StyledLink
        selected={location.pathname.indexOf(name) === 1}
        to={`/${name}`}
      >
        {capitalise(name)}
      </StyledLink>
    </LinkWrapper>
  )
  return (
    <AppBar color="inherit" position="sticky">
      <Toolbar>
        <HomeButton>BAO</HomeButton>
        <Tab name="trades" />
        <Tab name="advisors" />
        <Tab name="content" />
        <Tab name="conversations" />
        <UserIcon onClick={(e) => setAnchorEl(e.currentTarget)}>
          <div>
            {user
              ? `${user.firstName.charAt(0).toUpperCase()}${user.lastName
                  ?.charAt(0)
                  .toUpperCase()}`
              : null}
          </div>
        </UserIcon>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={async () => {
                  await signOut(auth)
                  navigate('/')
                }}
              >
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary="Sign out" />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </Toolbar>
    </AppBar>
  )
}
