import styled from 'styled-components'
import { Box, Button as _Button, Stack } from '@mui/material'
import { Close } from '@mui/icons-material'
import { LoadingButton as MuiLoadingButton } from '@mui/lab'

export const Wrapper = styled.div`
  max-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  padding: 24px 32px;
  width: 100%;
  display: flex;
  border-top: 1px solid #d7d7d7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: lighter;
  margin-top: auto;
`

export const Button = styled(_Button)<{ width?: number }>`
  height: 40px;
  ${({ width }) => (width ? `width: ${width}px;` : '')}
`

export const LoadingButton = styled(MuiLoadingButton)<{ width?: number }>`
  height: 40px;
  ${({ width }) => (width ? `width: ${width}px;` : '')}
`

export const Row = styled.div<{
  justify?: string
  align?: string
  spacing?: number
}>`
  display: flex;
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: ${({ align }) => align || 'center'};
  ${({ spacing }) =>
    spacing
      ? `
    > * + * {
      margin-left: ${spacing * 8}px;
    }
  `
      : ''}
`

export const ModalContent = styled(Stack)`
  padding: 20px;
  width: 420px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
`

export const PaddedStack = styled(Stack)`
  padding: 20px;
`

export const CloseIcon = styled(Close)`
  cursor: pointer;
`

export const SelectPlaceholder = styled(Box)`
  color: #a2a2a2;
`
