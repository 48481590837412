import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { auth } from '../firebase'
import {
  TextField,
  Button as _Button,
  Card,
  LinearProgress,
} from '@mui/material'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useUser } from 'context/user'
import { Role } from 'types'
import styled from 'styled-components'

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoginSection = styled(Card)`
  display: flex;
  max-width: 416px;
  justify-content: center;
  width: 100%;
`

const LoginSectionInner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 256px;
  width: 100%;
  padding-top: 56px;
  padding-bottom: 40px;
  > * + * {
    margin-top: 16px;
  }
`

const Title = styled.div`
  font-size: 36px;
`

const Button = styled(_Button)`
  margin-top: 28px;
`

const Space = styled.div`
  height: 4px;
`

export const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [authUser] = useAuthState(auth)
  const [isLoading, setIsLoading] = useState(false)
  const [loginError, setLoginError] = useState<string | null>(null)
  const navigate = useNavigate()
  const { user } = useUser()
  useEffect(() => {
    if (authUser && user !== undefined) {
      const { roles } = user || { roles: [] as Role[] }
      if (roles.includes('root')) {
        navigate('/trades')
      } else {
        signOut(auth)
        setLoginError('Insufficent Permissions')
      }
      setIsLoading(false)
    }
  }, [authUser, user, navigate])
  return (
    <Container>
      <LoginSection>
        <LoginSectionInner>
          <Title>Sign in</Title>
          <TextField
            error={!!loginError}
            label="Email"
            variant="standard"
            value={email}
            helperText={loginError}
            onChange={(e) => {
              setLoginError(null)
              setEmail(e.target.value)
            }}
          />
          <TextField
            label="Password"
            variant="standard"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={async () => {
              setIsLoading(true)
              try {
                await signInWithEmailAndPassword(auth, email, password)
              } catch (e) {
                setIsLoading(false)
                setLoginError('Incorrect Login Details')
              }
            }}
          >
            Sign in
          </Button>
          {isLoading ? <LinearProgress /> : <Space />}
        </LoginSectionInner>
      </LoginSection>
    </Container>
  )
}
export default Login
