import { Nav, Wrapper } from 'components'
import { PropsWithChildren } from 'react'
import { Link as _Link } from 'react-router-dom'
import { TableCell as _TableCell } from '@mui/material'
import styled from 'styled-components'

export const ListPageContainer = styled.div`
  padding: 16px 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Link = styled(_Link)`
  text-decoration: none;
`

export const TableCell = styled(_TableCell)<{ width?: string }>`
  ${({ width }) => (width ? `width: ${width};` : '')}
`

export const ListPageTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  font-size: 28px;
  font-weight: bold;
`

export const ListPage = (props: PropsWithChildren) => {
  return (
    <Wrapper>
      <Nav />
      <ListPageContainer>{props.children}</ListPageContainer>
    </Wrapper>
  )
}
