import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { db } from '../../firebase'
import { collection, getDocs } from 'firebase/firestore'
import {
  Auth,
  Button,
  CloseIcon,
  Link,
  ListPage,
  ListPageTitle,
  Row,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
} from 'components'
import {
  Table,
  TableBody,
  TableContainer,
  TextField,
  CircularProgress,
} from '@mui/material'
import { Add, Search as SearchIcon } from '@mui/icons-material'
import { Collection } from 'types'

interface Trade {
  title?: string
  id: string
  topicCount?: number
  advisorIds?: string[]
}

export const Trades = () => {
  const navigate = useNavigate()
  const [trades, setTrades] = useState<Trade[] | null>(null)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const _trades: Trade[] = []
      await getDocs(collection(db, Collection.Trades)).then((data) =>
        data.forEach((doc) => _trades.push({ ...doc.data(), id: doc.id }))
      )
      setTrades(_trades)

      const tradeTopicCountMap: Record<string, number> = {}

      await getDocs(collection(db, Collection.Topics)).then((data) =>
        data.forEach((doc) => {
          const topic = doc.data()
          if (topic.tradeId) {
            if (!tradeTopicCountMap[topic.tradeId]) {
              tradeTopicCountMap[topic.tradeId] = 0
            }
            tradeTopicCountMap[topic.tradeId] =
              tradeTopicCountMap[topic.tradeId] + 1
          }
        })
      )

      setTrades(
        _trades.map((trade: Trade) => ({
          ...trade,
          topicCount: tradeTopicCountMap[trade.id] || 0,
        }))
      )
    }

    if (trades === null) {
      fetchData()
    }
  }, [trades])

  return (
    <Auth>
      <ListPage>
        <Row>
          <ListPageTitle>Trades</ListPageTitle>
          <Row spacing={3}>
            <TextField
              size="small"
              InputProps={{
                startAdornment: <SearchIcon color="disabled" />,
                endAdornment: (
                  <CloseIcon color="disabled" onClick={() => setSearchTerm('')} />
                ),
              }}
              placeholder="search..."
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
            <Link to={'/trades/add'}>
              <Button variant="contained">
                <Add />
                Add Trade
              </Button>
            </Link>
          </Row>
        </Row>
        <TableContainer component={TableWrapper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="50%">Trade Name</TableCell>
                <TableCell align="center" width="25%">
                  # of Topics
                </TableCell>
                <TableCell align="center" width="25%">
                  # of Advisors
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trades ? (
                (trades || ([] as Trade[]))
                  .filter((trade) =>
                    (trade.title ?? '')
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                  .sort((a, b) => (a.title ?? '').localeCompare(b.title ?? ''))
                  .map((trade, i) => (
                    <TableRow
                      key={i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => navigate(`/trades/${trade.id}`)}
                    >
                      <TableCell component="th" scope="row">
                        {trade.title}
                      </TableCell>
                      <TableCell align="center">{trade.topicCount}</TableCell>
                      <TableCell align="center">
                        {(trade.advisorIds || []).length}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell colSpan={3} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ListPage>
    </Auth>
  )
}
