import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import {
  FIREBASE_ENVIRONMENT,
  FirebaseEnvironment
} from './env';

const firebaseConfig = (FIREBASE_ENVIRONMENT === FirebaseEnvironment.Prod) ? {
  apiKey: 'AIzaSyAWTnXvBX7LdeU3x4YkKPiPr2sb49JfebY',
  authDomain: 'bao-app-c2956.firebaseapp.com',
  databaseURL: 'https://bao-app-c2956.firebaseio.com',
  projectId: 'bao-app-c2956',
  storageBucket: 'bao-app-c2956.appspot.com',
  messagingSenderId: '289139275835',
  appId: '1:289139275835:web:4e6fa3d9bc579cc0b4c41e',
  measurementId: 'G-L770SRXB99',
} : {
  apiKey: 'AIzaSyDiiXClDMEX-Xz0j_QjXt-MVbsq2cNYRRk',
  authDomain: 'bao-app-dev.firebaseapp.com',
  projectId: 'bao-app-dev',
  storageBucket: 'bao-app-dev.appspot.com',
  messagingSenderId: '1011141979708',
  appId: '1:1011141979708:web:95c009d437b27db7575d39',
  measurementId: 'G-HXHNC1Q53S'
}

const app = initializeApp(firebaseConfig)

const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)

export { db, auth, app, storage }
