import { Auth, Nav } from 'components'
import styled from 'styled-components'

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 64px);
  width: 100%;
`

const Text = styled.div`
  font-style: italic;
  margin-bottom: 32px;
`

export const Conversations = () => {
  return (
    <Auth>
      <>
        <Nav />
        <Page>
          {' '}
          <Text>Coming soon</Text>
        </Page>
      </>
    </Auth>
  )
}
