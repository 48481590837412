import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db } from '../../firebase'
import { doc, getDoc } from 'firebase/firestore'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useUser } from 'context/user'
import { Role } from 'types'
import { TitleBar, Row } from 'components'
import { useParams } from 'react-router-dom'
import {
  CircularProgress,
  Stack,
  TextField,
  Button,
  Typography,
} from '@mui/material'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-top: 65px;
  height: 100vh;
`

const Container = styled.div`
  padding: 16px 40px;
`

const StyledTextField = styled(TextField)`
  width: 292.5px;
`

const getPasswordError = (a: string, b: string): string | null => {
  let error = null
  if (a !== b) {
    error = 'passwords do not match'
  }
  if (!error && !a) {
    error = 'please enter a password'
  }

  if (a.length < 6) {
    error = 'passwords must be at least 6 characters'
  }
  return error
}

export const Invitations = () => {
  const { inviteId } = useParams()
  const [validInvite, setValidInvite] = useState<boolean | undefined>()
  const [authUser] = useAuthState(auth)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [signupComplete, setSignupComplete] = useState(false)
  const [userEmail, setUserEmail] = useState<string>()
  const { user } = useUser()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function loadInvitation() {
      if (inviteId) {
        try {
          const invitationData = await getDoc(doc(db, 'invitations', inviteId))
          const { redeemedAt, email } = invitationData.data() || {
            redeemedAt: true,
          }
          setValidInvite(!redeemedAt)
          setUserEmail(email)
        } catch (e) {
          console.error('failed to retrieve invitation')
          setValidInvite(false)
        }
      } else {
        setValidInvite(false)
      }
    }

    loadInvitation()
  }, [inviteId])

  useEffect(() => {
    if (authUser && user !== undefined) {
      const { roles } = user || { roles: [] as Role[] }
      if (roles.includes('root')) {
        navigate('/trades')
      } else {
        signOut(auth)
      }
      setIsLoading(false)
    }
  }, [authUser, user, navigate])

  const acceptInvitation = async () => {
    const error = getPasswordError(password, confirmPassword)

    if (error) {
      setPasswordError(error)
    } else {
      setIsLoading(true)
      try {
        const newUser: any = await httpsCallable(
          getFunctions(),
          'acceptInvitation'
        )({ inviteId, password }).then((result) => result)
        setIsLoading(false)
        const roles = newUser?.data?.roles ?? []
        const email = newUser?.data?.email || ''
        if (roles.includes('root')) {
          await signInWithEmailAndPassword(auth, email, password)
        } else {
          setSignupComplete(true)
        }
      } catch (e) {
        console.log('failed to accept invitation:', e)
        setIsLoading(false)
      }
    }
  }

  return (
    <Wrapper>
      <TitleBar>Invitation</TitleBar>
      <Container>
        <Row justify="center">
          {signupComplete ? (
            <div>
              Your account is activated. Please download
              {' '}<a href="https://apps.apple.com/au/app/building-advice-online/id1546045976">iPhone</a>
              {' '} or <a href="https://play.google.com/store/apps/details?id=com.bao">Android</a> app,
              finish the registration and start using the app!
            </div>
          ) : isLoading || validInvite === undefined ? (
            <CircularProgress />
          ) : validInvite ? (
            <Stack spacing={2}>
              <Row justify="center">
                <h3>Create a password</h3>
              </Row>
              <Row>
                <Stack spacing={1}>
                  <Typography variant="body1">{userEmail}</Typography>
                  <StyledTextField
                    size="small"
                    error={!!passwordError}
                    placeholder={'Password'}
                    type={'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <StyledTextField
                    size="small"
                    error={!!passwordError}
                    helperText={passwordError}
                    placeholder={'Confirm password'}
                    type={'password'}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <Button variant="contained" onClick={acceptInvitation}>
                    Save password
                  </Button>
                </Stack>
              </Row>
            </Stack>
          ) : (
            <Row>Invitation is not valid</Row>
          )}
        </Row>
      </Container>
    </Wrapper>
  )
}
