import { useState } from 'react'
import { auth } from '../../firebase'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { TitleBar, Row } from 'components'
import {
  CircularProgress,
  Stack,
  TextField,
  Button,
} from '@mui/material'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-top: 65px;
  height: 100vh;
`

const Container = styled.div`
  padding: 16px 40px;
`

const StyledTextField = styled(TextField)`
  width: 292.5px;
`

export const DeleteAccount = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [error, setError] = useState<object | null>(null)

  const reset = () => {
    setEmail('')
    setPassword('')
    setIsDeleted(false)
    setIsDeleting(false)
    setError(null)
  }

  const deleteAccount = async () => {
    try {
      setIsDeleting(true)

      await signInWithEmailAndPassword(auth, email, password)

      await httpsCallable(getFunctions(), 'deleteAccount')({})

      await signOut(auth)

      setIsDeleting(false)
      setIsDeleted(true)
    } catch (err) {
      console.log(err)

      setIsDeleting(false)
      setError({})
    }
  }

  return (
    <Wrapper>
      <TitleBar>Delete account</TitleBar>
      <Container>
        <Row justify="center">
          {isDeleted ? (
            <div>
              Your account has been deleted. All related data has been deleted. You may now close this page.
            </div>
          ) : isDeleting ? (
            <CircularProgress />
          ) : error ? (
            <Stack spacing={2}>
              <Row>Failed to delete account</Row>
              <Row>
                <Button variant="contained" onClick={reset}>
                  Try again
                </Button>
              </Row>
            </Stack>
          ) : (
            <Stack spacing={2}>
              <Row justify="center">
                <div>
                  <p>
                    Enter your email and password to delete your account.
                  </p>
                  <p>
                    All related data will also be deleted.
                  </p>
                </div>
              </Row>
              <Row>
                <Stack spacing={1}>
                  <StyledTextField
                    size="small"
                    placeholder={'Email'}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <StyledTextField
                    size="small"
                    placeholder={'Password'}
                    type={'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button variant="contained" onClick={deleteAccount}>
                    Delete account
                  </Button>
                </Stack>
              </Row>
            </Stack>
          )}
        </Row>
      </Container>
    </Wrapper>
  )
}
