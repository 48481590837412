import {
  FileLoader,
  UploadAdapter,
} from '@ckeditor/ckeditor5-upload/src/filerepository'
import {
  getDownloadURL,
  ref,
  StorageReference,
  uploadBytesResumable,
  UploadTask,
} from 'firebase/storage'
import { storage } from '../firebase'

export class FirebaseStorageUploadAdapter implements UploadAdapter {
  private uploadTask?: UploadTask

  constructor(
    private readonly loader: FileLoader,
    private readonly contentId: string
  ) {}

  public abort(): void {
    this.uploadTask?.cancel()
  }

  public async upload(): Promise<Record<string, string>> {
    const file = await this.loader.file
    if (!file) {
      throw new Error('File not found')
    }

    const fileRef = this.getFileStorageRef(this.contentId, file.name)
    const uploadTask = uploadBytesResumable(fileRef, file)
    this.uploadTask = uploadTask

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          this.loader.uploadTotal = snapshot.totalBytes
          this.loader.uploaded = snapshot.bytesTransferred
          this.loader.uploadedPercent =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        (error) => {
          reject(error)
        },
        () => {
          resolve(
            // TODO: handle errors
            getDownloadURL(uploadTask.snapshot.ref).then((url) => ({
              default: url,
            }))
          )
        }
      )
    })
  }

  private getFileStorageRef(
    contentId: string,
    filePath: string
  ): StorageReference {
    return ref(storage, `content/${contentId}/editor-images/${filePath}`)
  }
}
