import { Chip } from '@mui/material'
import styled from 'styled-components'
import { UserStatus } from 'types'

const InvitedChip = styled(Chip)`
  color: grey;
`

interface UserStatusChipProps {
  hasCompletedProfile?: boolean
  hasLinkedStripeAccount?: boolean
  userStatus?: UserStatus
}

export const UserStatusChip = (props: UserStatusChipProps) => {
  switch (props.userStatus) {
    case UserStatus.Invited:
      return <InvitedChip variant="outlined" label={props.userStatus} />

    case UserStatus.Active:
      if (props.hasCompletedProfile && props.hasLinkedStripeAccount) {
        return <Chip color="success" label={props.userStatus} />
      } else {
        return <InvitedChip variant="outlined" label="Pending" />
      }

    case UserStatus.Deactivated:
      return <Chip color="error" label={props.userStatus} />

    default:
      return <>{props.userStatus}</>
  }
}
