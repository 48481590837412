export type Role = 'user' | 'root' | 'advisor'

export interface User {
  id: string
  roles: Role[]
  email: string
  name: string
  firstName: string
  lastName: string
}

export enum UserStatus {
  Invited = 'Invited',
  Active = 'Active',
  Deactivated = 'Deactivated',
  Deleted = 'Deleted',
}

export enum Collection {
  Content = 'content',
  Topics = 'topics',
  Trades = 'trades',
  Users = 'users',
}

export enum ContentStatus {
  Approved = 'Approved',
  Published = 'Published',
}
