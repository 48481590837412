import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react'
import { db } from '../firebase'
import {
  doc,
  collection,
  getDocs,
  updateDoc,
  setDoc,
  query,
  where,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore'
import { v4 as uuid } from 'uuid'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useNavigate } from 'react-router-dom'
import {
  TextField,
  Stack,
  Grid,
  MenuItem,
  Chip,
  Autocomplete,
  Box,
  Checkbox,
} from '@mui/material'
import {
  AlertModal,
  Button,
  DetailsPageContainer,
  Footer,
  LoadingButton,
  SelectPlaceholder,
  TitleBar,
  TradeDetails,
  UserStatusChip,
  Wrapper,
  ConfirmationModal,
} from 'components'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import { sendInvite } from 'utils'
import styled from 'styled-components'
import { Collection, Role, UserStatus } from 'types'
import { useSnackbar } from 'notistack'

export interface AdvisorDetails extends Partial<AdvisorDocumentData> {
  id?: string
}

interface TradeDocumentData {
  advisorIds?: string[]
}

interface AdvisorDocumentData extends Partial<AdvisorFormData> {
  hasCompletedProfile?: boolean
  hasLinkedStripeAccount?: boolean
  isBuilder?: boolean
  roles?: Role[]
  status?: UserStatus
  photoUrlAvatar?: string
  photoUrlInsurance?: string
  photoUrlLicence?: string
}

interface AdvisorFormData {
  firstName: string
  lastName: string
  phone?: string
  phoneLandline?: string
  email: string
  isBuilder: boolean
  states?: string[]
  postcodes?: string[]
  tradeIds: string[]
  abn?: string
  contractorLicenceNumber?: string
}

interface AdvisorDetailsPageProps {
  advisor?: AdvisorDetails
}

const Select = styled(TextField)`
  > div {
    > div {
      min-height: 32px !important;
      display: flex;
      align-items: center;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
`

const ImageBox = styled(({src, ...rest}: {src: string}) => (
  <Box
    component={'img'}
    src={src}
    title="Click to download"
    onClick={() => {
      saveAs(src);
    }}
    {...rest}
  />
))`
  height: 200px;
  width: 200px;
  object-fit: cover;
  cursor: pointer;

  & + & {
    margin-left: 10px;
  }
`

const expireAllInvitationsForUserId = (userId: string) =>
  getDocs(
    query(collection(db, 'invitations'), where('userId', '==', userId))
  ).then((data) => {
    let ids: string[] = []
    data.forEach((doc) => ids.push(doc.id))
    return Promise.all(
      ids.map((id) =>
        updateDoc(doc(db, 'invitations', id), {
          redeemedAt: Date.now(),
        })
      )
    )
  })

const updateAuthUser = (userId: string, status: UserStatus) =>
  httpsCallable(getFunctions(), 'updateAuthUser')({ userId, status })

export const AdvisorDetailsPage = (props: AdvisorDetailsPageProps = {}) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false)
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false)
  const [isUserExistsModalOpen, setIsUserExistsModalOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isActivating, setIsActivating] = useState(false)
  const [isDeactivating, setIsDeactivating] = useState(false)
  const [isResendingInvitation, setIsResendingInvitation] = useState(false)
  const [trades, setTrades] = useState<TradeDetails[]>()
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<AdvisorFormData>({
    defaultValues: { isBuilder: false, postcodes: [], states: [], tradeIds: [] },
  })

  useEffect(() => {
    if (props.advisor) {
      reset({
        ...props.advisor,
      })
    }
  }, [props.advisor, reset])

  useEffect(() => {
    const fetchData = async () => {
      const tradeData: TradeDetails[] = []
      // TODO: handle errors
      await getDocs(collection(db, Collection.Trades)).then((data) =>
        data.forEach((doc) => {
          tradeData.push({ ...doc.data(), id: doc.id })
        })
      )
      setTrades(
        tradeData.sort((a, b) => (a.title ?? '').localeCompare(b.title ?? ''))
      )
    }
    fetchData()
  }, [])

  const pageTitle = props.advisor ? 'Edit Advisor' : 'Invite Advisor'
  const saveButtonText = props.advisor ? 'Save' : 'Invite'
  const states = ['VIC', 'QLD', 'NSW', 'NT', 'WA', 'SA', 'TAS']

  const resendInvitation = async () => {
    if (props.advisor?.id) {
      setIsResendingInvitation(true)

      try {
        await sendInvite(props.advisor.id, props.advisor.email!)
        enqueueSnackbar('An invitation has been re-sent.', {
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
          variant: 'success',
        })
        navigate('/advisors')
      } catch (error) {
        console.error(error)

        // TODO: handle errors
      }

      setIsResendingInvitation(false)
    }
  }

  const activateAdvisor = async () => {
    if (props.advisor?.id) {
      setIsActivating(true)

      try {
        await updateDoc<Partial<AdvisorDocumentData>>(
          doc(db, Collection.Users, props.advisor.id),
          {
            status: UserStatus.Active,
          }
        )
        await updateAuthUser(props.advisor.id, UserStatus.Active)

        navigate('/advisors')
      } catch (error) {
        console.error(error)

        // TODO: handle errors
      }

      setIsActivating(false)
    }
  }

  const closeDeactivationModal = () => {
    setIsDeactivateModalOpen(false)
  }
  const openDeactivationModal = () => {
    setIsDeactivateModalOpen(true)
  }
  const deactivateAdvisor = async () => {
    if (props.advisor?.id) {
      setIsDeactivating(true)

      try {
        await expireAllInvitationsForUserId(props.advisor.id)
        await updateDoc<Partial<AdvisorDocumentData>>(
          doc(db, Collection.Users, props.advisor.id),
          {
            status: UserStatus.Deactivated,
          }
        )
        await updateAuthUser(props.advisor.id, UserStatus.Deactivated)

        navigate('/advisors')
      } catch (error) {
        console.error(error)

        // TODO: handle errors
      }

      setIsDeactivating(false)
    }
  }

  const closeDeletionModal = () => {
    setIsDeletionModalOpen(false)
  }
  const openDeletionModal = () => {
    setIsDeletionModalOpen(true)
  }
  const deleteAdvisor = async () => {
    if (props.advisor?.id) {
      setIsDeleting(true)

      try {
        await expireAllInvitationsForUserId(props.advisor.id)
        await updateDoc<Partial<AdvisorDocumentData>>(
          doc(db, Collection.Users, props.advisor.id),
          {
            status: UserStatus.Deleted,
          }
        )
        await updateAuthUser(props.advisor.id, UserStatus.Deleted)

        navigate('/advisors')
      } catch (error) {
        console.error(error)

        // TODO: handle errors
      }

      setIsDeleting(false)
    }
  }

  const saveChanges: SubmitHandler<AdvisorFormData> = async (formData) => {
    const docId = props.advisor?.id ?? uuid()
    const docRef = doc(db, Collection.Users, docId)

    const { tradeIds, ...data } = formData

    if (props.advisor?.id) {
      // TODO: handle errors
      await updateDoc<Partial<AdvisorDocumentData>>(docRef, {
        ...data,
      })
    } else {
      // TODO: handle errors

      const snapshot = await getDocs(
        query(
          collection(db, Collection.Users),
          where('email', '==', data.email)
        )
      )

      if (snapshot.size > 0) {
        setIsUserExistsModalOpen(true)

        return;
      }

      await Promise.all([
        setDoc<Partial<AdvisorDocumentData>>(docRef, {
          ...data,
          status: UserStatus.Invited,
          roles: ['advisor'],
        }),
        sendInvite(docId, data.email),
      ])
    }

    const prevTradeIdMap = (props?.advisor?.tradeIds || []).reduce(
      (obj: Record<string, boolean>, id) => ({ ...obj, [id]: true }),
      {}
    )

    const newTradeIds: string[] = []

    tradeIds.forEach((id) => {
      if (!prevTradeIdMap[id]) {
        newTradeIds.push(id)
      } else {
        delete prevTradeIdMap[id]
      }
    })

    const removedTradeIds = Object.keys(prevTradeIdMap)

    await Promise.all([
      ...newTradeIds.map((tradeId) =>
        updateDoc<Partial<TradeDocumentData>>(
          doc(db, Collection.Trades, tradeId),
          {
            advisorIds: arrayUnion(docId),
          }
        )
      ),
      ...removedTradeIds.map((tradeId) =>
        updateDoc<Partial<TradeDocumentData>>(
          doc(db, Collection.Trades, tradeId),
          {
            advisorIds: arrayRemove(docId),
          }
        )
      ),
    ])

    await updateDoc<Partial<AdvisorDocumentData>>(docRef, {
      ...data,
    })

    navigate('/advisors')
  }

  const advisorName = `${getValues('firstName')} ${getValues('lastName')}`

  const DeactivationModal = (
    <ConfirmationModal
      confirmButtonLabel={'Deactivate'}
      isConfirming={isDeactivating}
      isOpen={isDeactivateModalOpen}
      message={
        <>
          Are you sure you want to deactivate advisor “
          <strong>{advisorName}</strong>
          ”?
        </>
      }
      modalTitle={'Deactivate advisor'}
      onClose={closeDeactivationModal}
      onConfirm={deactivateAdvisor}
    />
  )
  const DeletionModal = (
    <ConfirmationModal
      confirmButtonLabel={'Delete'}
      isConfirming={isDeleting}
      isOpen={isDeletionModalOpen}
      message={
        <>
          Are you sure you want to delete advisor “
          <strong>{advisorName}</strong>
          ”?
        </>
      }
      modalTitle={'Delete advisor'}
      onClose={closeDeletionModal}
      onConfirm={deleteAdvisor}
    />
  )
  const UserExistsModal = (
    <AlertModal
      isOpen={isUserExistsModalOpen}
      message={<>User is already invited or registered</>}
      modalTitle={'Error'}
      onClose={() => setIsUserExistsModalOpen(false)}
    />
  );

  const gridSpacing = 2
  const formControlSpacing = 1
  return (
    <>
      <form onSubmit={handleSubmit(saveChanges)}>
        <Wrapper>
          <TitleBar>{pageTitle}</TitleBar>
          <DetailsPageContainer>
            <Grid container spacing={gridSpacing}>
              {props.advisor?.id && (
                <Grid container item spacing={gridSpacing}>
                  <Grid item xs>
                    <Stack spacing={formControlSpacing} alignItems={'center'}>
                      <label>Status</label>
                      <UserStatusChip
                        hasCompletedProfile={props.advisor?.hasCompletedProfile}
                        hasLinkedStripeAccount={props.advisor?.hasLinkedStripeAccount}
                        userStatus={props.advisor?.status}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              )}

              <Grid container item spacing={gridSpacing}>
                <Grid item xs>
                  <Stack spacing={formControlSpacing}>
                    <label htmlFor="firstName">First name*</label>
                    <TextField
                      size="small"
                      id="firstName"
                      {...register('firstName', {
                        required: 'Please enter First name',
                      })}
                      error={errors.firstName != null}
                      helperText={errors.firstName?.message}
                      placeholder="Enter first name"
                    />
                  </Stack>
                </Grid>

                <Grid item xs>
                  <Stack spacing={formControlSpacing}>
                    <label htmlFor="lastName">Last name*</label>
                    <TextField
                      size="small"
                      id="lastName"
                      {...register('lastName', {
                        required: 'Please enter Last name',
                      })}
                      error={errors.lastName != null}
                      helperText={errors.lastName?.message}
                      placeholder="Enter last name"
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid container item spacing={gridSpacing}>
                <Grid item xs>
                  <Stack spacing={formControlSpacing}>
                    <label htmlFor="phone">Mobile phone number*</label>
                    <TextField
                      size="small"
                      id="phone"
                      {...register('phone', {
                        required: 'Please enter Mobile phone number',
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: 'Please enter valid Mobile number'
                        }
                      })}
                      error={errors.phone != null}
                      helperText={errors.phone?.message}
                      placeholder="Enter mobile phone number"
                    />
                  </Stack>
                </Grid>

                <Grid item xs>
                  <Stack spacing={formControlSpacing}>
                    <label htmlFor="phoneLandline">Landline phone number</label>
                    <TextField
                      size="small"
                      id="phoneLandline"
                      {...register('phoneLandline')}
                      placeholder="Enter landline phone number"
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid container item spacing={gridSpacing}>
                <Grid item xs>
                  <Stack spacing={formControlSpacing}>
                    <label htmlFor="email">Email*</label>
                    <TextField
                      size="small"
                      id="email"
                      {...register('email', {
                        required: 'Please enter Email',
                        pattern: {
                          value: /^[^@]+@[^@]+$/,
                          message: 'Please enter valid email'
                        }
                      })}
                      error={errors.email != null}
                      helperText={errors.email?.message}
                      placeholder="Enter email"
                      disabled={props.advisor?.id != null}
                    />
                  </Stack>
                </Grid>

                <Grid container item xs direction={'row'} alignItems={'flex-end'}>
                  <Stack alignItems={'flex-end'}>
                    {props.advisor?.id && props.advisor.status === 'Invited' && (
                      <LoadingButton
                        loading={isResendingInvitation}
                        variant="outlined"
                        onClick={resendInvitation}
                      >
                        Resend invitation
                      </LoadingButton>
                    )}
                  </Stack>
                </Grid>
              </Grid>

              <Grid container item spacing={gridSpacing}>
                <Grid item xs alignItems={'center'}>
                  <Stack direction={'row'} alignItems={'center'} spacing={formControlSpacing}>
                    <Controller
                      name="isBuilder"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Checkbox
                            {...field}
                            id="isBuilder"
                            checked={getValues('isBuilder')}
                          />
                          <label htmlFor="isBuilder">Builder</label>
                        </>
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>

              {
                (props.advisor?.photoUrlAvatar || props.advisor?.photoUrlInsurance || props.advisor?.photoUrlLicence) && (
                  <Grid container item spacing={gridSpacing}>
                    <Grid item xs>
                      {
                        Boolean(props.advisor?.photoUrlAvatar) && (
                          <ImageBox src={props.advisor?.photoUrlAvatar as string}/>
                        )
                      }
                      {
                        Boolean(props.advisor?.photoUrlInsurance) && (
                          <ImageBox src={props.advisor?.photoUrlInsurance as string}/>
                        )
                      }
                      {
                        Boolean(props.advisor?.photoUrlLicence) && (
                          <ImageBox src={props.advisor?.photoUrlLicence as string}/>
                        )
                      }
                    </Grid>
                  </Grid>
                )
              }

              <Grid container item spacing={gridSpacing}>
                <Grid item xs>
                  <Stack spacing={formControlSpacing}>
                    <label htmlFor="states">States*</label>
                    <Controller
                      name="states"
                      control={control}
                      rules={{
                        required: 'Please choose States',
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="states"
                          select
                          error={errors.states != null}
                          helperText={errors.states?.message}
                          SelectProps={{
                            multiple: true,
                            displayEmpty: true,
                            renderValue: (value: any) => {
                              const selected = (value as string[]) ?? []
                              return selected.length === 0 ? (
                                <SelectPlaceholder>
                                  Choose states
                                </SelectPlaceholder>
                              ) : (
                                <Stack
                                  direction={'row'}
                                  flexWrap={'wrap'}
                                  spacing={1}
                                >
                                  {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                  ))}
                                </Stack>
                              )
                            },
                          }}
                          defaultValue={[]}
                        >
                          {(() => {
                            const selectedStates = getValues('states')
                            return (states ?? []).map((state, i) => (
                              <MenuItem key={state} value={state}>
                                <Checkbox
                                  checked={
                                    (selectedStates || []).indexOf(state) > -1
                                  }
                                />
                                {state}
                              </MenuItem>
                            ))
                          })()}
                        </Select>
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid container item spacing={gridSpacing}>
                <Grid item xs>
                  <Stack spacing={formControlSpacing}>
                    <label htmlFor="postcodes">Postcodes*</label>
                    <Controller
                      name="postcodes"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          size="small"
                          id="postcodes"
                          multiple
                          options={[]}
                          defaultValue={[]}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            (value as unknown as string[]).map(
                              (option: string, index: number) => (
                                <Chip
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              )
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={errors.postcodes != null}
                              helperText={errors.postcodes?.message}
                              inputProps={{
                                ...params.inputProps,
                                maxLength: 4
                              }}
                              placeholder="Enter postcodes"
                            />
                          )}
                          onChange={(_event, newValue) => {
                            if (newValue.length > 0) {
                              const last = newValue[newValue.length - 1] as string

                              if (!/^[0-9]{4}$/.test(last)) {
                                return
                              }
                            }

                            setValue('postcodes', newValue as string[])
                            trigger('postcodes');
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid container item spacing={gridSpacing}>
                <Grid item xs>
                  <Stack spacing={formControlSpacing}>
                    <label htmlFor="tradeIds">Trades*</label>
                    <Controller
                      name="tradeIds"
                      control={control}
                      rules={{
                        required: 'Please choose Trades',
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="tradeIds"
                          error={errors.tradeIds != null}
                          helperText={errors.tradeIds?.message}
                          select
                          SelectProps={{
                            size: 'small',
                            multiple: true,
                            displayEmpty: true,
                            renderValue: (value: any) => {
                              const selected = (value as string[]) ?? []
                              return selected.length === 0 ? (
                                <SelectPlaceholder>
                                  Choose trades
                                </SelectPlaceholder>
                              ) : (
                                <Stack direction={'row'} flexWrap={'wrap'}>
                                  {selected.map((s, i) => {
                                    const title = trades?.find(
                                      (t) => t.id === s
                                    )?.title
                                    return (
                                      <Box key={i} sx={{ m: 0.5 }}>
                                        <Chip label={title} />
                                      </Box>
                                    )
                                  })}
                                </Stack>
                              )
                            },
                          }}
                          defaultValue={[]}
                        >
                          {(() => {
                            const selectedTradeIds = getValues('tradeIds')
                            return (trades ?? []).map((trade, i) => (
                              <MenuItem key={i} value={trade.id}>
                                <Checkbox
                                  checked={
                                    selectedTradeIds.indexOf(trade.id) > -1
                                  }
                                />
                                {trade.title}
                              </MenuItem>
                            ))
                          })()}
                        </Select>
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid container item spacing={gridSpacing}>
                <Grid item xs>
                  <Stack spacing={formControlSpacing}>
                    <label htmlFor="abn">ABN</label>
                    <TextField
                      size="small"
                      id="abn"
                      {...register('abn')}
                      placeholder="Enter ABN"
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid container item spacing={gridSpacing}>
                <Grid item xs>
                  <Stack spacing={formControlSpacing}>
                    <label htmlFor="contractorLicenceNumber">
                      Contractor licence number
                    </label>
                    <TextField
                      size="small"
                      id="contractorLicenceNumber"
                      {...register('contractorLicenceNumber')}
                      placeholder="Enter contractor licence number"
                    />
                  </Stack>
                </Grid>
              </Grid>

              {props.advisor?.id && (
                <>
                  {props.advisor.status === 'Deactivated' && (
                    <Grid container item spacing={gridSpacing}>
                      <Grid item xs>
                        <LoadingButton
                          loading={isActivating}
                          variant="outlined"
                          onClick={() => activateAdvisor()}
                        >
                          Activate
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  )}

                  {props.advisor.status === 'Active' && (
                    <Grid container item spacing={gridSpacing}>
                      <Grid item xs>
                        <Button
                          variant="outlined"
                          onClick={() => openDeactivationModal()}
                        >
                          Deactivate
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container item spacing={gridSpacing}>
                    <Grid item xs>
                      <Button
                        variant="outlined"
                        onClick={() => openDeletionModal()}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </DetailsPageContainer>

          <Footer>
            <Button
              variant="outlined"
              width={160}
              onClick={() => navigate('/advisors')}
            >
              Cancel
            </Button>
            <Button variant="contained" width={160} type="submit">
              {saveButtonText}
            </Button>
          </Footer>
        </Wrapper>
      </form>

      {DeactivationModal}
      {DeletionModal}
      {UserExistsModal}
    </>
  )
}
