import { useEffect, useState } from 'react'
import { db } from '../../firebase'
import { collection, getDocs, where, query } from 'firebase/firestore'
import {
  Auth,
  Button,
  CloseIcon,
  Link,
  ListPage,
  Row,
  SelectPlaceholder,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
  TradeDetails,
  TradeDetailsTopic,
} from 'components'
import {
  CircularProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Stack,
  Chip,
} from '@mui/material'
import { Add, Search } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import styled from 'styled-components'
import { Collection, ContentStatus, User } from 'types'

const ListPageTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
`

const TitleWrapper = styled(Stack)`
  margin-top: 16px;
  margin-bottom: 20px;
`

interface ContentDetails extends ContentDocumentData {
  tradeTitle: string
  topicTitle: string
  creatorName: string
}

interface ContentDocumentData {
  id?: string
  status?: ContentStatus
  topicId?: string
  tradeId?: string
  userId?: string
}

export const Content = () => {
  const navigate = useNavigate()
  const [content, setContent] = useState<ContentDetails[]>()
  const [filteredContent, setFilteredContent] = useState<ContentDetails[]>()
  const [searchTerm, setSearchTerm] = useState('')
  const [trades, setTrades] = useState<TradeDetails[]>([])
  const [selectedTradeId, setSelectedTradeId] = useState<string>()

  useEffect(() => {
    const fetchData = async () => {
      const tradeData: TradeDetails[] = []
      await getDocs(collection(db, Collection.Trades)).then((data) =>
        data.forEach((doc) => {
          tradeData.push({ ...doc.data(), id: doc.id })
        })
      )
      setTrades(tradeData)

      const topicData: TradeDetailsTopic[] = []
      await getDocs(collection(db, Collection.Topics)).then((data) =>
        data.forEach((doc) => {
          topicData.push({ ...doc.data(), id: doc.id })
        })
      )

      const userData: Partial<User>[] = []
      await getDocs(collection(db, Collection.Users)).then((data) =>
        data.forEach((doc) => {
          userData.push({ ...doc.data(), id: doc.id })
        })
      )

      const contentData: ContentDetails[] = []
      await getDocs<ContentDocumentData>(collection(db, Collection.Content))
      await getDocs<ContentDocumentData>(
        query(
          collection(db, Collection.Content),
          where('status', '!=', 'Deleted')
        )
      ).then((data) =>
        data.forEach((doc) => {
          const contentDocumentData: ContentDocumentData = {
            ...doc.data(),
            id: doc.id,
          }
          const tradeTitle =
            tradeData.find((t) => t.id === contentDocumentData.tradeId)
              ?.title ?? ''
          const topicTitle =
            topicData.find((t) => t.id === contentDocumentData.topicId)
              ?.title ?? ''
          const creator = userData.find(
            (u) => u.id === contentDocumentData.userId
          )
          const creatorName = `${creator?.firstName} ${creator?.lastName}`
          return contentData.push({
            ...contentDocumentData,
            tradeTitle,
            topicTitle,
            creatorName,
          })
        })
      )
      setContent(
        [...contentData].sort((a, b) => {
          return (
            a.tradeTitle.localeCompare(b.tradeTitle) ||
            a.tradeTitle.localeCompare(b.topicTitle)
          )
        })
      )
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (content) {
      setFilteredContent(
        content.filter((c) => {
          const loweredSearchTerm = searchTerm.toLowerCase()
          return (
            (!selectedTradeId || c.tradeId === selectedTradeId) &&
            (c.tradeTitle.toLowerCase().includes(loweredSearchTerm) ||
              c.topicTitle.toLowerCase().includes(loweredSearchTerm) ||
              c.creatorName.toLowerCase().includes(loweredSearchTerm) ||
              (c.status ?? '').toLowerCase().includes(loweredSearchTerm))
          )
        })
      )
    }
  }, [content, searchTerm, selectedTradeId])

  const tradeFilterChange = (props: SelectChangeEvent) => {
    setSelectedTradeId(props.target.value)
  }

  return (
    <Auth>
      <ListPage>
        <TitleWrapper spacing={2}>
          <ListPageTitle>Content</ListPageTitle>

          <Row>
            <Select
              displayEmpty
              defaultValue=""
              onChange={tradeFilterChange}
              size="small"
            >
              <MenuItem value="">
                <SelectPlaceholder>All trades</SelectPlaceholder>
              </MenuItem>
              {trades.map((t, i) => (
                <MenuItem key={i} value={t.id}>
                  {t.title}
                </MenuItem>
              ))}
            </Select>

            <Row spacing={3}>
              <TextField
                size="small"
                InputProps={{
                  startAdornment: <Search color="disabled" />,
                  endAdornment: (
                    <CloseIcon
                      color="disabled"
                      onClick={() => setSearchTerm('')}
                    />
                  ),
                }}
                placeholder="search..."
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <Link to={'/content/add'}>
                <Button variant="contained">
                  <Add />
                  Add Content
                </Button>
              </Link>
            </Row>
          </Row>
        </TitleWrapper>

        <TableContainer component={TableWrapper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Trade</TableCell>
                <TableCell>Topic</TableCell>
                <TableCell>Creator</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredContent ? (
                filteredContent.map((content, i) => (
                  <TableRow
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() => navigate(`/content/${content.id}`)}
                  >
                    <TableCell>{content.tradeTitle}</TableCell>
                    <TableCell>{content.topicTitle}</TableCell>
                    <TableCell>{content.creatorName}</TableCell>
                    <TableCell align="center">
                      <Chip
                        color={
                          content.status === ContentStatus.Published
                            ? 'success'
                            : 'default'
                        }
                        label={content.status}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell colSpan={5} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ListPage>
    </Auth>
  )
}
