import { useEffect, useState } from 'react'
import { db } from '../firebase'
import { doc, collection, getDocs, updateDoc, setDoc } from 'firebase/firestore'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'
import {
  TextField,
  Stack,
  Grid,
  MenuItem,
  Box,
} from '@mui/material'
import {
  Button,
  ContentEditor,
  DetailsPageContainer,
  Footer,
  SelectPlaceholder,
  TitleBar,
  TradeDetails,
  TradeDetailsTopic,
  Wrapper,
  ConfirmationModal,
} from 'components'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Collection, ContentStatus } from 'types'
import { useUser } from 'context/user'

export interface ContentDetails extends Partial<ContentDocumentData> {
  id?: string
}

interface ContentDocumentData extends ContentFormData {
  status?: ContentStatus
  userId: string
}

interface ContentFormData {
  tradeId?: string
  topicId?: string
  content?: string
}

interface ContentDetailsPageProps {
  content?: ContentDetails
}

const Select = styled(TextField)`
  > div {
    > div {
      min-height: 32px !important;
      display: flex;
      align-items: center;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
`

export const ContentDetailsPage = ({
  content,
}: ContentDetailsPageProps = {}) => {
  const { user } = useUser()
  const navigate = useNavigate()
  const [contentId, setContentId] = useState(content?.id)
  const [trades, setTrades] = useState<TradeDetails[]>()
  const [topics, setTopics] = useState<TradeDetailsTopic[]>()
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<ContentFormData>({
    defaultValues: {
      tradeId: '',
      topicId: '',
    },
  })
  const selectedTradeId = watch('tradeId')

  useEffect(() => {
    if (content) {
      reset({
        ...content,
      })
    }
  }, [content, reset])

  useEffect(() => {
    setContentId(content?.id ?? uuid())
  }, [content?.id])

  useEffect(() => {
    const fetchData = async () => {
      const tradeData: TradeDetails[] = []
      // TODO: handle errors
      await getDocs(collection(db, Collection.Trades)).then((data) =>
        data.forEach((doc) => {
          tradeData.push({ ...doc.data(), id: doc.id })
        })
      )
      setTrades(tradeData)

      const topicData: TradeDetailsTopic[] = []
      // TODO: handle errors
      await getDocs(collection(db, Collection.Topics)).then((data) =>
        data.forEach((doc) => {
          topicData.push({ ...doc.data(), id: doc.id })
        })
      )
      setTopics(topicData)
    }

    fetchData()
  }, [])

  const resetTopics = () => {
    setValue('topicId', '')
  }

  const pageTitle = content ? 'Edit Content' : 'Add Content'

  const deleteContent = async () => {
    if (content?.id) {
      setIsDeleting(true)

      try {
        await updateDoc(doc(db, Collection.Content, content.id), {
          status: 'Deleted',
        })

        navigate('/content')
      } catch (error) {
        console.error(error)

        // TODO: handle errors
      }

      setIsDeleting(false)
    }
  }

  const discardChanges = () => {
    navigate('/content')
  }

  const saveChanges: SubmitHandler<ContentFormData> = async (data) => {
    const docRef = doc(db, Collection.Content, contentId!)

    if (content?.id) {
      // TODO: handle errors
      await updateDoc<Partial<ContentDocumentData>>(docRef, data)
    } else {
      // TODO: handle errors
      await Promise.all([
        setDoc<Partial<ContentDocumentData>>(docRef, {
          ...data,
          status: user?.roles.includes('root')
            ? ContentStatus.Approved
            : ContentStatus.Published,
          userId: user?.id,
        }),
      ])
    }

    navigate('/content')
  }

  const gridSpacing = 2
  const formControlSpacing = 1
  return (
    <>
      <form onSubmit={handleSubmit(saveChanges)}>
        <Wrapper>
          <TitleBar>{pageTitle}</TitleBar>
          <DetailsPageContainer>
            <Grid container spacing={gridSpacing}>
              <Grid container item spacing={gridSpacing}>
                <Grid item xs>
                  <Stack spacing={formControlSpacing}>
                    <label htmlFor="tradeId">Trade</label>
                    <Controller
                      name="tradeId"
                      control={control}
                      rules={{ required: 'Please choose Trade' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="tradeId"
                          error={errors.tradeId != null}
                          helperText={errors.tradeId?.message}
                          select
                          SelectProps={{
                            size: 'small',
                            displayEmpty: true,
                            onChange: (e) => {
                              field.onChange(e)
                              resetTopics()
                            },
                          }}
                        >
                          <MenuItem value="">
                            <SelectPlaceholder>Choose Trade</SelectPlaceholder>
                          </MenuItem>
                          {(trades ?? []).map((trade, i) => (
                            <MenuItem key={i} value={trade.id}>
                              {trade.title}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid container item spacing={gridSpacing}>
                <Grid item xs>
                  <Stack spacing={formControlSpacing}>
                    <label htmlFor="topicId">Topic</label>
                    <Controller
                      name="topicId"
                      control={control}
                      rules={{ required: 'Please choose Topic' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="topicId"
                          disabled={!selectedTradeId}
                          error={errors.topicId != null}
                          helperText={errors.topicId?.message}
                          select
                          SelectProps={{
                            size: 'small',
                            displayEmpty: true,
                          }}
                        >
                          <MenuItem value="">
                            <SelectPlaceholder>Choose Topic</SelectPlaceholder>
                          </MenuItem>
                          {(
                            topics?.filter(
                              (t) => t.tradeId === selectedTradeId
                            ) ?? []
                          ).map((topic) => (
                            <MenuItem key={topic.id} value={topic.id}>
                              {topic.title}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid container item spacing={gridSpacing}>
                <Grid item xs>
                  <Stack spacing={formControlSpacing}>
                    <label htmlFor="content">Content</label>
                    <Box>
                      {contentId && (
                        <ContentEditor
                          id="content"
                          config={{ placeholder: 'Rich content' }}
                          data={content?.content}
                          onChange={(_event, editor) =>
                            setValue('content', editor.getData())
                          }
                          contentId={contentId}
                        />
                      )}
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            {content?.id ? (
              <Grid container item mt={1} spacing={gridSpacing}>
                <Grid item xs>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => setIsDeletionModalOpen(true)}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </DetailsPageContainer>

          <Footer>
            <Button variant="outlined" width={160} onClick={discardChanges}>
              Cancel
            </Button>
            <Button variant="contained" width={160} type="submit">
              Save
            </Button>
          </Footer>
        </Wrapper>
        <ConfirmationModal
          confirmButtonLabel={'Delete'}
          isConfirming={isDeleting}
          isOpen={isDeletionModalOpen}
          message={<>Are you sure you want to delete this content entry?</>}
          modalTitle={'Delete content'}
          onClose={() => setIsDeletionModalOpen(false)}
          onConfirm={deleteContent}
        />
      </form>
    </>
  )
}
