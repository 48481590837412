import { AdvisorDetails, AdvisorDetailsPage, Auth } from 'components'
import { db } from '../../firebase'
import {
  doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Backdrop, CircularProgress } from '@mui/material'
import { Collection } from 'types'

export const Edit = () => {
  const { id } = useParams()
  const [advisor, setAdvisor] = useState<AdvisorDetails>({ id })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        setIsLoading(true)

        const advisorData: any = (
          await getDoc(doc(db, Collection.Users, id))
        ).data()
        advisorData.tradeIds = []

        try {
          await getDocs(
            query(
              collection(db, Collection.Trades),
              where('advisorIds', 'array-contains', id)
            )
          ).then((data) =>
            data.forEach(
              (doc) =>
                (advisorData.tradeIds = [...advisorData.tradeIds, doc.id])
            )
          )
        } catch (error) {
          console.error(error)

          // TODO: handle errors
        }

        setAdvisor({
          ...advisorData,
          id,
        })

        setIsLoading(false)
      }

      fetchData()
    }
  }, [id])

  return (
    <Auth>
      <>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress />
        </Backdrop>
        <AdvisorDetailsPage advisor={advisor} />
      </>
    </Auth>
  )
}
