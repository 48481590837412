import { CKEditor, CKEditorProps } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Editor, Plugin } from '@ckeditor/ckeditor5-core'
import { useEffect, useState } from 'react'
import { FirebaseStorageUploadAdapter } from './FirebaseStorageUploadAdapter'

interface ContentEditorProps extends Partial<CKEditorProps> {
  contentId: string
}

export const ContentEditor = ({ contentId, ...props }: ContentEditorProps) => {
  const [plugins, setPlugins] = useState<typeof Plugin[]>()
  useEffect(() => {
    setPlugins([createImageUploadAdapterPlugin(contentId)])
  }, [contentId])

  return (
    <>
      {plugins && (
        <CKEditor
          {...props}
          editor={ClassicEditor}
          config={{
            extraPlugins: plugins,
            toolbar: {
              removeItems: ['blockQuote', 'mediaEmbed'],
            },
          }}
          onReady={(editor) => {
            editor.editing.view.change((writer) => {
              writer.setStyle(
                'min-height',
                '300px',
                editor.editing.view.document.getRoot()!
              )
            })
          }}
        />
      )}
    </>
  )
}

function createImageUploadAdapterPlugin(contentId: string): typeof Plugin {
  return function (editor: Editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new FirebaseStorageUploadAdapter(loader, contentId)
    }
  } as unknown as typeof Plugin
}
