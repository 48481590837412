import { ReactNode } from 'react'
import { Stack, Modal, Typography } from '@mui/material'
import {
  Button,
  CloseIcon,
  ModalContent,
  PaddedStack,
  Row,
} from 'components'
import styled from 'styled-components'

const CenteredText = styled.p`
  text-align: center;
`

interface ConfirmationModalProps {
  isOpen: boolean
  message: ReactNode
  modalTitle: ReactNode
  onClose: () => void
}

export const AlertModal = (props: ConfirmationModalProps) => {
  return (
    <Modal
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContent>
        <Row justify="flex-end">
          <CloseIcon onClick={props.onClose} />
        </Row>
        <PaddedStack spacing={3}>
          <Row justify="center">
            <Typography fontSize={28} fontWeight="bold" textAlign="center">
              {props.modalTitle}
            </Typography>
          </Row>
          <Stack spacing={1}>
            <CenteredText>{props.message}</CenteredText>
          </Stack>
          <Row justify="center">
            <Button
              variant="contained"
              width={160}
              onClick={props.onClose}
            >
              OK
            </Button>
          </Row>
        </PaddedStack>
      </ModalContent>
    </Modal>
  )
}
