import styled from 'styled-components'

export const TitleBar = styled.div`
  z-index: 1;
  position: fixed;
  background-color: #fff;
  top: 0;
  width: 100%;
  min-height: 65px;
  display: flex;
  border-bottom: 1px solid #d7d7d7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`

export const DetailsPageContainer = styled.div`
  margin: 0 auto;
  padding: 97px 16px 105px 16px;
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const DetailsPageTitle = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
  font-size: 28px;
  font-weight: bold;
`
