import { db } from '../firebase'
import { setDoc, doc } from 'firebase/firestore'
import { v4 } from 'uuid'
import { Collection } from 'types'

export const createUser = async ({
  firstName,
  lastName,
  email,
  id,
  verified,
  roles = [],
}: {
  firstName: string
  lastName: string
  email: string
  id?: string
  verified?: string
  roles?: string[]
}) => {
  const user = {
    firstName,
    lastName,
    email,
    verified: verified || false,
    roles: [...roles],
  }
  const userId = id || v4()
  try {
    await setDoc(doc(db, Collection.Users, userId), user)
    return { ...user, id: userId }
  } catch (e) {
    console.log('failed to create user:', e)
    return null
  }
}

export const sendInvite = async (newUserId: string, newUserEmail: string) => {
  const token = v4()
  try {
    const createdInvite = await setDoc(doc(db, 'invitations', token), {
      email: newUserEmail,
      userId: newUserId,
      token,
    })
    return createdInvite
  } catch (e) {
    console.log('failed to invite user:', e)
    return null
  }
}
