import { useState, useEffect } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { auth, db } from '../firebase'
import { createContext, useContext } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Collection, User } from 'types'

const UserContext = createContext(
  {} as {
    user: User | undefined | null
    setUser: (newUser: User | null) => void
  }
)

export const UserProvider = ({ children }: any) => {
  const [authUser] = useAuthState(auth)
  const [user, setUser] = useState<User | undefined | null>()
  useEffect(() => {
    if (authUser && user === undefined) {
      getUser(authUser.uid)
    }
  }, [authUser, user])

  const getUser = async (uid: string) => {
    try {
      getDoc(doc(db, Collection.Users, uid)).then((doc) => {
        setUser({ ...doc.data(), id: doc.id } as User)
      })
    } catch (error) {
      setUser(null)
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = (): { user: User | undefined | null } =>
  useContext(UserContext)
